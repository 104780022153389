@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Geologica:wght@900&display=swap");

:root {
  --color: #81007f;
  --shcolor: #3d003c;
  --back: #17181b;
  --tcolor: #fff;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  user-select: none;
  font-family: "Quicksand", sans-serif;
}
body {
  overflow-x: hidden;
  background-color: var(--back);
  transition: 0.3s ease;
  color: var(--color);
}

#root {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 1.5vw 5vw;
}
.App {
  width: 100%;
  height: 100%;
}

.buttons {
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vw;
}

.buttons a {
  text-decoration: none;
  color: currentColor;
}
button {
  padding: 1vw 2vw;
  height: auto;
  border: 0.2vw solid var(--color);
  background-color: transparent;
  border-radius: 0.8vw;
  cursor: pointer;
  font-weight: bolder;
  font-size: 1vw;
  color: var(--color);
  transition: 0.3s ease;
  width: 15vw;
}

button:hover {
  background-color: var(--color);
  color: #fff;
}

.title {
  position: absolute;
  top: -4vw;
  left: 7vw;
  font-size: 15vw;
  color: rgb(199, 199, 199);
  /* text-shadow: 0 0 1vw var(--shcolor); */
}
.page_title {
  padding: 0 0 2vw 0;
  font-size: 2.5vw;
  color: var(--color);
  font-weight: 900;
}
.page_title12 {
  padding: 0 0 2vw 0;
  font-size: 3vw;
  color: var(--color);
  font-weight: 900;
}

.name_back {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2vw;
}

.chekboks {
  position: fixed;
  bottom: 1vw;
  right: 1vw;
}
.modalFace .Add_p {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 60vh;
  gap: 2vw;
}
.Add_p {
  display: grid;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  gap: 0.5vw;
}
.Add_p input {
  background-color: transparent;
  outline: none;
  border: 0.2vw solid var(--color);
  padding: 1.1vw 2vw;
  border-radius: 0.8vw;
  font-size: 1vw;
  color: var(--tcolor);
}
table {
  width: 100%;
  border: 0.2vw solid #81007f;
  border-collapse: collapse;
  border: none;
}
table thead {
  background-color: var(--color);
}
th {
  letter-spacing: 0.1vw;
  color: #fff !important;
  padding: 1.5vw 1vw;
}
td {
  padding: 1vw 1vw;
}
th,
td {
  text-align: left;
  border-bottom: 0.1vw solid var(--color);
  border-top: none;
  border-left: none;
  border-right: none;
}

th:nth-child(2),
th:nth-child(3) {
  width: 2vw;
}

.ttable th:nth-child(2) {
  width: 80vw;
}
tr td p svg,
td,
th {
  font-size: 0.8vw;
  color: var(--tcolor);
}
.del {
  font-size: 1.4vw;
}
.edit_modal,
.open_item_modal,
.edit_modal2,
.down_modal,
.edit_modal3 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale(0);
  background-color: var(--back);
  padding: 2vw 3vw;
}
.edit_modal.active,
.open_item_modal.active,
.edit_modal2.active,
.down_modal.active,
.edit_modal3.active {
  transform: scale(1);
}

.btn {
  background-color: #81007f !important;
  color: #fff !important;
}
.btn:hover {
  background-color: transparent !important;
  color: #81007f !important;
}

.open_item_modal .name_back .page_title,
.qarz1 {
  color: #ff0000;
}
.open_item_modal .name_back .page_title2,
.qarz2 {
  color: #008000;
}
.qizil {
  background-color: #7d0000;
}
.yashil {
  background-color: #0c380c;
}

.df {
  display: flex;
  align-items: center;
  gap: 10px;
}
#select {
  background-color: transparent;
  border: 0.2vw solid #81007f;
  outline: none;
  color: #616161;
  padding: 1.05vw 1vw 1.05vw 0.5vw;
  border-radius: 0.8vw;
  font-size: 1vw;
}
.open_item_modal,
.edit_modal2 {
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 0.2vw;
}

::-webkit-scrollbar-thumb {
  background-color: #81007f;
}

.error {
  padding: 0;
  margin: 0;
  color: #ff0000;
}
.result_error {
  color: #ff0000;
  font-size: 1vw;
}
.res_but {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 0.5vw;
}
.Add_p div input {
  width: 100%;
}
.Add_p div select {
  width: 100%;
}

.buttonlar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
}

.Qarzniqaytarish .Add_p {
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5vh;
}

.Qarzniqaytarish .Add_p div input {
  width: 17vw;
}
.css-10hburv-MuiTypography-root {
  font-size: 1.6vw !important;
}
.list {
  border-right: 0.05vw solid #616161;
  padding-right: 3.2vw;
  height: 75vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.catalogs {
  width: 100%;
  display: flex;
  gap: 1vw;
  padding-bottom: 2vw;
}
.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
  padding: 0.5vw !important;
  padding-left: 0 !important;
}
.css-tlelie-MuiListItemText-root {
  margin: 0.1vw !important;
}
.list_card .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
  padding: 0.5vw !important;
}
.ItemModal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #17181b;
  margin-top: -100vw;
  z-index: -1;
  padding: 1.5vw 5vw;
}
.ItemModal.active {
  margin: 0;
  z-index: 111;
}
.modalFace .Add_p select,
.CreateP .Add_p select,
.update_modal .Add_p select,
.umumiyfoyda .Add_p select {
  background-color: transparent;
  outline: none;
  border: 0.2vw solid var(--color);
  padding: 1.1vw 2vw;
  border-radius: 0.8vw;
  font-size: 1vw;
  color: var(--tcolor);
  width: 15vw;
}
.modalFace .Add_p select option,
.CreateP .Add_p select option,
.update_modal .Add_p select option,
.umumiyfoyda .Add_p select option {
  color: #000;
}
.name_back span {
  cursor: pointer;
}
.warehause .buttons {
  min-height: 30vh;
}
.sotilgan .buttons {
  min-height: 70vh;
}
.cur_page {
  color: #c000bd;
}
.CreateP .Add_p label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.yoniq {
  width: 5px;
  height: 5px;
  background-color: #008000;
}
.ochiq {
  width: 5px;
  height: 5px;
  background-color: #7d0000;
}
.update_modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #17181b;
  padding: 2vw;
  display: none;
}
.update_modal.active {
  margin-top: 0;
  display: block;
  z-index: 999999999;
}
.ttable tr:nth-child(even) {
  background-color: rgb(33, 35, 37);
}

.CreateC .Add_p,
.UpdateP .Add_p {
  display: flex;
  min-height: 60vh;
}

.list {
  text-transform: capitalize;
}

.update_modal {
  overflow: auto;
}
.xomashyolar {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  row-gap: 10px;
}
.xomashyolar form {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  row-gap: 10px;
}

.usd_uzs {
  display: flex;
  align-items: center;
  gap: 30px;
}
.usd_uzs span {
  color: #008000;
  font-size: 35px;
}
.umumiyfoyda .ttable th:nth-child(2) {
  width: 10vw;
}
.sidebar_table {
  display: flex;
  align-items: flex-start;
}
.sidebar {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 20vw;
}
.umumiyfoyda tr td {
  height: 2vw !important;
}
.sidebar h1 {
  margin-left: 0.5vw;
}
.sidebar .Add_p {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}
.jami {
  color: #008000;
}
.jami span {
  color: #7d0000;
}

/* Media */

@media screen and (max-width: 768px) {
  .buttons {
    flex-direction: column;
  }
  button,
  .Add_p input,
  .Add_p select {
    font-size: 3vw !important;
    width: 80vw !important;
  }
  .Add_p {
    display: block;
    font-size: 5vw !important;
  }
  .name_back {
    flex-direction: column-reverse;
    gap: 1vw;
  }
  .css-10hburv-MuiTypography-root {
    font-size: 3vw !important;
  }
  .ttable th:nth-child(2) {
    width: auto !important;
  }
  .tr td p svg,
  td,
  th {
    font-size: 2vw;
  }
  .sidebar_table {
    flex-direction: column;
  }
  #root {
    padding: 1vw !important;
  }
  .Add_p {
    flex-direction: column;
  }
  .df {
    flex-direction: column-reverse;
  }

  .Add_p input,
  .Add_p select {
    width: 45vw !important;
  }
  .res_but {
    margin-top: 10vw;
  }
  .catalogs {
    flex-direction: column;
  }
  .catalogs .list {
    border-right: none;
    border-bottom: 0.05vw solid #616161;
    padding: 4vw;
  }

  .catalogs table {
    margin: 4vw;
  }
  .List_Items {
    padding: 4vw;
  }
}

@media screen and (max-width: 425px) {
  button {
    font-size: 5vw !important;
    width: 80vw !important;
  }
  .trrrrr button {
    width: auto !important;
  }
  .page_title {
    font-size: 4vw;
  }
  table {
    width: 300%;
  }
  .css-10hburv-MuiTypography-root {
    font-size: 5vw !important;
  }
  .UpdateP table,
  .ttable,
  .modal .batafsil_table {
    width: 100%;
    font-size: 5vw;
  }
  .tr td p svg,
  td,
  th {
    font-size: 5vw;
  }
}

.Updatee_P .list {
  border: none;
}

.trrrrr button {
  width: auto;
}
.modal {
  overflow-y: auto;
  display: none;
  padding: 1.5vw 5vw;
}

.modal.active {
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  background-color: #17181b;
}

.batafsil_table {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.batafsil_table table {
  width: auto;
}

.testAdd {
  min-height: auto !important;
  margin-bottom: 2vh;
}

.search_input {
  width: 100%;
  padding: 1vw 2vw;
  background-color: transparent;
  border: 0.2vw solid #81007f;
  border-radius: 0.8vw;
  margin-bottom: 1vh;
  color: #fff;
  outline: none;
}
